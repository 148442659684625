
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'TecbanCardShadow',
  props: {
    imgName: String,
    viewName: String,
    cardLabel: String,
    pstyle: String
  },
  data() {
    return {
      fillSVG1: 'var(--home-card1-icon-color)',
      fillSVG2: 'var(--home-card1-icon-color)'
    }
  },
  methods: {
    test(message: string) : void {
      console.log(message);
    },
    goToView(viewName: string, params: any) : void {
      this.$router.push({ name: viewName, params: params});
    }
  }
});
