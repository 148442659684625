<template>

  <div id="loadingModalId" class="loadingPanel" v-show="showModal">

    <div class="loadMessage">
      <span>{{internalizationLoadModal.loadModalWaitLabel}}</span>
    </div>

    <div class="loadIcon">
      <LoadTecbanIcon></LoadTecbanIcon>
    </div>

  </div>

</template>

<script>
import { getInternalization } from '@/util/util.js';

/* Internalization, used if not use BD to get data 
var internalizationLoadModalTmp = {

  "loadModalWaitLabel": "Aguarde",

}
/* */

export default {
  props: {
  },
  data() {
    return {
      showModal: false,
    }
  },
  methods: {
    enable(){
      window.scrollTo(0, 0);
      document.body.style.overflow = 'hidden';
      this.showModal = true;
    },
    disable(){
      this.showModal = false;
      document.body.style.overflow = 'auto';
    }
  },
  created(){
    this.internalizationLoadModal = getInternalization();
  }
}
</script>

<style scoped>
.loadingPanel {
  position: fixed;
  top: 72px;
  left: 0;
  width: 98.95vw;
  height: 100vh;
  background-color: var(--page-background-color);
}
.loadMessage, .loadIcon{
  position: absolute;
  right: 50%;
}
.loadMessage {
  top: 20%;
  transform: translate(50%,-50%);
  font-size: 18px;
  line-height: 24px;
  font-weight: bold;
  color: var(--loading-title-color);
}
.loadIcon{
  top: 50%;
  transform: translate(50%,-50%);
}
</style>