
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'TextBox',
  props: {
    text: String,
  },
  methods: {
    test(message: string) : void {
      console.log(message);
    }
  }
});
