<template>

  <Dialog  v-model:visible="display" :closable="false" >
    <slot></slot>
  </Dialog>

</template>

<script>

export default {
  data() {
    return {
    }
  },
  props: {
    dp: Boolean,
  },
  created() {
    this.display = true
  }
}
</script>

<style scoped>

</style>