{
  "IF": "BANCO",
  "btnVoltar": "Voltar",
  "btnCancelar":"Cancelar",
  "btnConfirm":"Confirmar",
  "btnIr": "Ir",
  "contactUsTitle":"Atendimento",
  "txtRodape": "Acessar Portal do Cidadão",
  "openBankingCompartilhamentos" : "Meus Compartilhamentos",
  "openBankingConsentimentos" : "Meus Consentimentos de Pagamentos",
  "lerTermosDeUso": "Ler Termos de Uso",
  "infoListaConsentimentos": "Aqui são exibidos os consentimentos solicitados referentes a pagamentos. Confirme se o pagamento foi efetivado consultando sua movimentação bancária (extratos).",
  "meusConsentimentosTitulo" : "Meus Consentimentos de Pagamentos",
  "lblFiltrarMeusConsentimentos" : "Filtrar",
  "lblResumoConsentimento": "Resumo do Consentimento",
  "lblDadosPagadorTitulo": "Dados do pagador",
  "lblDadosBeneficiarioTitulo": "Dados do beneficiário",
  "lblDataInicPagamentoTitulo": "Data de iniciação de pagamento",
  "lblValorTitulo": "Valor",
  "lblFormaPagamentoTitulo": "Forma de pagamento",
  "lblconsentID": "ID do Consentimento",
  "msgConfirmadPagamento" : "Deseja confirmar o  pagamento?",
  "msgPagamentoEfetivado" : "Você será redirecionado  e seu <br> pagamento  efetivado!",
  "msgSobreOpenbanking" : "O que é Open Banking?",
  "btnConfirmapagamento" : "Confirmar pagamento",
  "bpnVoltar" : "Voltar",
  "testeIcon":"wpp-blank.svg",
  "bpnCacelar" : "Cancelar",
  "iconeNaoEncontrado": "Ícone não encontrado",
  "textSelectAccount": "Selecione sua Conta",
  "messageErrFetchingData":"Falha na comunicação.",
  "detailErrFetchingData":"Inicie a solicitação de consentimento novamente",
  "lblFaleConosco": "Atendimento",
  "deatilErrFetchingDataConsult":"Houve falha na comunicação. Tente novamente mais tarde.",
  "faqTitle":"Dúvidas Frequentes",
  "duvidas" : "Dúvidas Frequentes",
  "faq1" : "O que é Open Banking?",
  "faq1Expand":"O Open Banking, ou sistema financeiro aberto, propicia o compartilhamento padronizado de dados e serviços por meio de APIs (Application Programming Interfaces) por parte de instituições financeiras e demais instituições autorizadas a funcionar pelo Banco Central do Brasil. No caso de dados de clientes (pessoa física ou jurídica) é o cliente que decidirá quando e com quem ele deseja compartilhá-los no escopo do Open Banking, desde que seja com finalidades específicas e prazos determinados.",
  "faq2":"Quais são os benefícios do Open Banking?",
  "faq2Expand":"Com o Open Banking, espera-se a entrega de produtos e serviços financeiros a consumidores com maior agilidade, conveniência e segurança. Além da possibilidade de integração da prestação de serviços financeiros à  jornada digital do consumidor, são fornecidas condições para o surgimento de modelos de negócio que têm o cliente no centro. Esses novos modelos, que podem envolver, por exemplo, comparadores de preços, produtos e serviços, possibilitarão ao consumidor maior visibilidade e controle de sua vida financeira, bem como o acesso a produtos personalizados e a condições financeiras mais vantajosas e adequadas às suas necessidades, e de seus interesses e objetivos.",
  "faq3":"É possivel fazer a inicialização de pagamentos por uma outra instituição diferente da minha conta?",
  "faq3Expand":"Sim. Mediante seu prévio consentimento, será possível iniciar o pagamento a partir de uma instituição prestadora de serviço de iniciação de transação de pagamento, conforme a regulamentação em vigor.",
  "faq4":"Quem pode autorizar o compartilhamento de meus dados no escopo do Open Banking? ",
  "faq4Expand":"Poderão autorizar o compartilhamento de dados os clientes (tanto pessoas físicas como jurídicas) de instituições participantes do Open Banking com as quais mantêm relacionamento destinado à prestação de serviço financeiro ou à realização de operação financeira.",
  "faq5":"Quando poderei compartilhar os meus dados?",
  "faq5Expand":"A partir da 2ª fase do Open Banking, as instituições participantes possibilitarão aos clientes solicitarem o compartilhamento de informações cadastrais e transacionais de contas de depósito à  vista, de poupança, de pagamento pré-pagas, de cartão de crédito e de operações de crédito. No futuro, outras informações, por exemplo, sobre investimentos e seguros, também poderão ser compartilhadas, mediante prévio consentimento do cliente.",
  "faq6":"Com quem eu posso/poderei compartilhar os meus dados?",
  "faq6Expand":"Com instituições financeiras, de pagamento ou demais instituições autorizadas a funcionar pelo Banco Central do Brasil.",
  "faq7":"Eu estou/estarei automaticamente registrado no Open Banking?",
  "faq7Expand":"Não. Para compartilhar seus dados no Open Banking é necessária a sua manifestação ativa, na forma de consentimento para finalidades determinadas à  instituição para a qual deseja enviar seus dados. O consentimento terá prazo de validade compatível com essas finalidades, limitado a 12 meses. Esse processo será feito em ambiente digital seguro, inclusive com a identificação do consumidor. Atenção! A 1ª fase do Open Banking não envolve o compartilhamento de dados pessoais dos clientes, de forma que o consentimento não é exigido nessa etapa.",
  "faq8":"Como eu autorizo o compartilhamento dos meus dados pelo Open Banking?",
  "faq8Expand":"Primeiro, você deverá se identificar e fornecer seu consentimento no canal eletrônico da instituição que você quer que tenha acesso aos seus dados. Em seguida, você será redirecionado para a instituição que mantém tais dados, e nesse ambiente poderá autenticar a sua identidade e confirmar o compartilhamento. No último passo, você será redirecionado ao ambiente onde foi feita a solicitação inicial para a recepção de comunicação sobre a efetivação da solicitação de compartilhamento. Somente então seus dados serão compartilhados, observado o prazo de validade do consentimento. Quando for confirmar o compartilhamento de seus dados, você poderá revisar os dados que deseja compartilhar e verificar se estão corretos. Lembre-se de que todo o processo ocorrerá exclusivamente pelos canais eletrônicos das instituições.",
  "faq9":"Será cobrado algum valor do cliente pelo compartilhamento de dados?",
  "faq9Expand":"Não. Não será cobrado nenhum valor do cliente pelo compartilhamento de dados.",
  "faq10":"Recebi uma comunicação solicitando o compartilhamento dos meus dados, o que faço?",
  "faq10Expand":"Somente prossiga com o consentimento para compartilhamento de dados se você conhecer a informação a ser compartilhada e a instituição que a receberá, e se tiver interesse no compartilhamento desses dados. Caso desconfie da origem da comunicação recebida, entre em contato com a instituição que enviou a mensagem.",
  "faq11":"Como eu cancelo o acesso/compartilhamento aos meus dados?",
  "faq11Expand":"Você pode pedir o cancelamento do compartilhamento dos seus dados tanto na instituição a que deu o consentimento como na instituição que transmitirá a ela esses dados. Cada uma delas vai indicar a maneira exata para a revogação do consentimento, assim como os canais de atendimento disponíveis. Você sempre poderá pedir a retirada do consentimento pelo mesmo canal de atendimento em que ele foi concedido, se este ainda estiver disponível. Após a sua solicitação, o consentimento será cancelado de forma imediata ou, no caso da iniciação de pagamentos, em até um dia.",
  "faq12":"Por meio de quais canais posso solicitar o compartilhamento de dados e serviços do Open Banking?",
  "faq12Expand":"Você só poderá solicitar o compartilhamento de dados e serviços do Open Banking por meio de canais digitais como mobile e internet banking das instituições financeiras, de pagamento ou demais instituições autorizadas a funcionar pelo Banco Central do Brasil como participantes do Open Banking.",
  "faq13":"Ainda não sou correntista de um banco, mas tenho outros tipos de produtos em instituições reguladas pelo Banco Central (por exemplo, seguro, financiamento e credenciamento). Posso compartilhar dados no Open Banking? ",
  "faq13Expand":"Sim. Na Fase 4, após identificado pela instituição receptora dos dados, o cliente poderá consentir com o compartilhamento de dados do escopo do Open Banking que estejam mantidos em outras instituições participantes.",
  "faq14":"É seguro compartilhar meus dados no Open Banking?",
  "faq14Expand":"Preservar a segurança em relação aos dados e serviços compartilhados é um dos principais objetivos do Open Banking. Por isso, as instituições participantes devem cumprir uma série de requisitos para garantir a autenticidade, a segurança e o sigilo das informações compartilhadas. Estão previstas regras específicas para responsabilização da instituição e de seus dirigentes, com mecanismos de acompanhamento e controle do processo de compartilhamento e regras específicas de responsabilização da instituição e de seus dirigentes. Além disso, as instituições participantes devem observar outras exigências previstas na legislação e regulamentação vigentes para assegurar a segurança e a confiabilidade do processo de compartilhamento, a exemplo das regras relativas à  implementação de políticas de segurança cibernética.",
  "faq15":"Quem pode autorizar o compartilhamento de meus dados no  Open Banking?",
  "faq15Expand":"Só você pode autorizar o compartilhamento dos seus dados no Open Banking. As instituições financeiras ou de pagamento não poderão transmitir seus dados pessoais a terceiros sem o seu consentimento expresso.",
  
  "lblAviso": "Aviso",
  "msgAvisBuscaConsentPagamentos": "Aqui são exibidos os consentimentos solicitados referentes a pagamentos. Para verificar se o pagamento foi realizado, é necessário consultar sua movimentação bancária.",
  "lblRodapeConsentimentosPagamentos": "Atendimento",
  "tempoExcedido":"Tempo Excedido",
  "tempoExcedidoPag":"Você atingiu o limite de 05 minutos sem executar nenhuma ação.",
  "tempExcedidoSelectAccount":"Você atingiu o limite de 05 minutos sem executar nenhuma ação.",
  "mensagemDeInformacaoConsultaConsent": "Aqui são exibidos os consentimentos que ainda não foram aprovados e estão no prazo de validade. Para aprová-lo, você deve seguir com a solicitação na Iniciadora de Pagamentos.",
  "lblDetalhamentoConsentimentoIf": "Falha na validação",
  "erroDetalhamentoConsentimentoIf": "Dados de autenticação não conferem com os informados na Iniciadora de Pagamentos. Inicie a solicitação de consentimento novamente.",
  "lblInfoDetalharConsentAprovado": "Aqui são exibidos os consentimentos que você aprovou para pagamentos. Para verificar se o pagamento foi realizado, é necessário consultar sua movimentação bancária.",
  "lblInfoDetalharConsentimentoRejeitado": "Aqui são exibidos os consentimentos que você não aprovou.",
  "lblInfoDetalharConsentimentoAguardando": "Aqui são exibidos os consentimentos que ainda não foram aprovados e estão no prazo de validade. Para aprová-lo, você deve seguir com a solicitação na Iniciadora de Pagamentos.",
  "infoNenhumConsentEncontrado": "Nenhum consentimento para ser exibido no momento",
  "lblErrValidatingData": "Falha na validação",
  "messageErrValidatingData" : "Por favor, tente novamente mais tarde",
  "failValidlab":"Falha na Validação",
  "failValideDet":"Dados de autenticação não conferem com os informados na Iniciadora de Pagamentos. Inicie a solicitação de consentimento novamente.",
  "lblRedirectError":"Houve falha na comunicação. Por gentileza, verifique na movimentação da sua conta e também na Inciadora de Pagamento se a transação foi realizada, antes de tentar novamente",
  "lblCommunicationErrorConfirm": "Houve falha na comunicação. Inicie a solicitação de consentimento novamente.",
  "failCommunication":"Houve falha na comunicação. Inicie a solicitação de consentimento novamente.",
  "failRedirectCommunication":"Houve falha na comunicação. Por gentileza, verifique na movimentação  da sua conta e também na Inciadora de Pagamento se a transação foi realizada, antes de tentar novamente",
  "lblCpfValidationError": "Falha na Validação",
  "cpfValidationErrorDescription" : "Dados de autenticação não conferem com os informados na Iniciadora de Pagamentos. Inicie a solicitação de consentimento novamente."
}