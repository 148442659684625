<template>
  <div class="disableScreen" v-if="showModal">

    <div class="errIconMsg2">
      <div class="centered">
      </div>
    </div>
    <div class="errDetail2">
      <div>
        <div class="space2"></div>
        <div class="errIconMsgFont">{{messageErr}}</div>
        <div class="space2"></div>
        <div style="margin-bottom: 20px" class="errDetailFont">{{detailErr}}</div>
        <div v-if='detailErr2' class="errDetailFont">{{detailErr2}}</div>
        <div v-if='detailErr2' class="space2"></div>
        <Okbutton
          rotulo="Ok"
          tipo="submit"
          @botaoAtivado="disable()"
        />
      </div>
    </div>

  </div>

</template>

<script>

function goBack() {
  window.history.back();
}
export default {
  name: 'ConfirmModal',
  props: {
    tipo: String,
    messageErr: String,
    detailErr: String,
    detailErr2: String
  },
  data() {
    return {
      showModal: false,
      
    }
  },
  methods: {
    enable(){
      this.showModal = true;
    },
    disable(){
      this.showModal = false;
    },
  },
  created(){
    var timeout
      clearTimeout(timeout)
      timeout = setTimeout(() => {  
        if(this.showModal){
          window.history.back();
        }
      },  30000)
  }
}

</script>

<style scoped>
.disableScreen{
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: var(--exceptions-backgroundup-transparency-color);
}
.errIconMsg, .errIconMsg2, .errDetail, .errDetail2{
  text-align: center;
  width: 100%;
}
.errIconMsg2{
  height: 40%;
}
.errIconMsgFont {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 33px;
  color: var(--info-modal-myconsents-label-color);
}
.errDetailFont{
  color: var(--info-modal-myconsents-text-color);
}
.errDetail, .errDetail2{
  font-size: 18px;
  line-height: 24px;
  background-color: var(--exceptions-backgrounddown-solid-color);
  border-radius: 40px;
  border-top: 0.5px solid var(--exceptions-icons-color);
  border-bottom: 0.5px solid var(--exceptions-icons-color);
  position:absolute;
  -webkit-transition: top 1s;
  -moz-transition: top 1s;
}
.errDetail{
  height: 40%;
}
.errDetail2{
  height: 50%;
}
.centered{
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
}
.space{
  padding: none;
  margin: none;
  height: 40%;
}
.space2{
  padding: none;
  margin: none;
  height: 40px;
}
</style>