
import { defineComponent } from 'vue';
import { getLogoIniciadora } from '@/util/util.js';

export default defineComponent({
  name: 'TecbanRedirectCardShadow',
  props: {
    imgName: String,
    viewName: String,
    cardLabel: String,
    pstyle: String
  },
  data() {
    return {
      urlIniciadora: ''
    }
  },
  methods: {
    test(message: string) : void {
      console.log(message);
    },
    goToView(viewName: string, params: any) : void {
      this.$router.push({ name: viewName, params: params});
    }
  },
  mounted() {
    this.urlIniciadora = getLogoIniciadora();
  }
});
