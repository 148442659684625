<template>
  
  <NavBar
    v-if="appCreated"
    ref="mainNavbar"
    ifName="BANCO"
    :bntGoBackLabel="internalizationApp.topBarReturnLabel"
  />

  <router-view
    v-if="appCreated"
  />

  <LoadingModal
    v-if="appCreated"
    ref="loadingModal"
  />

  <ExceptionModal
    v-if="appCreated"
    ref="exceptionWarnigFetchingData"
    tipo='error'
    :messageErr="internalizationApp.fluxConsultConsErrFetchTimeOutLabel"
    :detailErr="internalizationApp.fluxConsultConsErrFetchTimeOutMsg"
  />

  <ExceptionModal
    v-if="appCreated"
    ref="exceptionWarnigFetchingDataPay"
    tipo='error'
    :messageErr="internalizationApp.fluxConfConsErrFetchTimeOutLabel"
    :detailErr="internalizationApp.fluxConfConsErrFetchTimeOutMsg"
  />

</template>

<script>
import { getTheme, getWithoutExpiry, setWithoutExpiry, getWithExp, setWithExp } from '@/util/util.js';
import { defineComponent } from 'vue';

import defaultInter from '@/internalization/default.json';
import amzInter from '@/internalization/amz.json';
import bcdInter from '@/internalization/bcd.json';
import bmxInter from '@/internalization/bmx.json';
import bs2Inter from '@/internalization/bs2.json';
import ccbInter from '@/internalization/ccb.json';
import cdsInter from '@/internalization/cds.json';
import letInter from '@/internalization/let.json';
import oriInter from '@/internalization/ori.json';
import voiInter from '@/internalization/voi.json';


export default defineComponent({
  data() {
    return {
      publicPath: process.env.BASE_URL,
      appCreated: false,

      internalization: defaultInter
    }
  },
  methods:{
    getInternalizationLocal(theme){

      switch(theme){
        case('amz'): return amzInter;
        case('bcd'): return bcdInter;
        case('bmx'): return bmxInter;
        case('bs2'): return bs2Inter;
        case('ccb'): return ccbInter;
        case('cds'): return cdsInter;
        case('let'): return letInter;
        case('ori'): return oriInter;
        case('voi'): return voiInter;
        default: return defaultInter;
      }
      
    },
    async getInternalizationBD(){

      let requestOptions = {
        method: 'GET',
        redirect: 'follow'
      };

      try{
        let response = await fetch(process.env.VUE_APP_CONSENTS+'/consent/tomdevoz', requestOptions);
        if (!response.ok) {
          return "fetchErr";
        }
        let tom = await response.json();

        return tom;
      }
      catch (error){
        return "fetchErr";
      }
    }
  },
  async created() {

    this.appCreated = false;
    
    window.onbeforeunload  = function(){
      window.scrollTo(0, 0);
    }

    /* theme */
    let theme = getTheme();
    document.write('<link rel="stylesheet" type="text/css" href="./ifThemes/' + theme + '.css">');
    /* */
    
    /* internalization */
    let internalizationTmp;
    if(process.env.VUE_APP_IF_NAME.toLowerCase().trim() == 'qa'){

      internalizationTmp = this.getInternalizationLocal(theme);
      setWithExp('internalization', JSON.stringify(internalizationTmp));
    }
    else{
      internalizationTmp = getWithExp('internalization');

      if (internalizationTmp != null){
        internalizationTmp = JSON.parse(internalizationTmp);
      }

      if (internalizationTmp == null || 
          internalizationTmp.ifCode == null || 
          internalizationTmp.ifCode.toLowerCase().trim() != process.env.VUE_APP_IF_NAME.toLowerCase().trim()){
        
        internalizationTmp = this.getInternalizationLocal(theme);

        // if any error occurs, local internalization will be used, if fi`s local internalization is not found, then local default will be used
        if (internalizationTmp == 'fetchErr'){
          internalizationTmp = this.getInternalizationLocal(process.env.VUE_APP_IF_NAME.toLowerCase().trim());
        }

        setWithExp('internalization', JSON.stringify(internalizationTmp));
      }

    }

    this.internalizationApp = internalizationTmp;
    
    this.appCreated = true;
    /* */
  }
})
</script>


<style>

/* Try to place and use only this global styles in app*/

body {
  margin:0;
  font-family: var(--font-family);
}

.p-component{
  font-family: var(--font-family) !important;
}

.radioP .p-radiobutton-box{
  border: 2px solid var(--radio-border-color) !important;
  background: none !important;
}
.radioP .p-focus{
  box-shadow: 0px 0px 0.3rem var(--radio-fill-color) !important;
}
.radioP .p-radiobutton-icon{
  background-color: var(--radio-fill-color) !important;
}

.checkP .p-checkbox-box{
  border: 2px solid var(--checkbox-border-color) !important;
  background: none !important;
}
.checkP .p-highlight{
  background: var(--checkbox-background-color) !important; 
}
.checkP .p-highlight .p-checkbox-icon{
  color: var(--checkbox-fill-color) !important;
  font-weight: 900 !important;
}
.checkP .p-focus{
  box-shadow: 0px 0px 0.3rem var(--checkbox-fill-color) !important;
}

.dropdownP{
  background: none !important;
  border: 2px solid var(--dropdown-border-color) !important;
}
.dropdownP.p-focus{
  box-shadow: 0px 0px 0.3rem var(--dropdown-focus-shadow-color) !important;
}
.dropdownP .p-dropdown-label, .dropdownP .p-dropdown-item{
  color: var(--dropdown-text-notselected-color) !important;
}
.dropdownP .pi{
  color: var(--dropdown-icon-color) !important;
}
.dropdownP .p-highlight{
  color: var(--dropdown-text-selected-color) !important;
  background-color: var(--dropdown-background-selected-color) !important;
}

.scrollbarP::-webkit-scrollbar, .dropdownP .p-dropdown-items-wrapper::-webkit-scrollbar {
  width: 10px;
}
.scrollbarP::-webkit-scrollbar-track, .dropdownP .p-dropdown-items-wrapper::-webkit-scrollbar-track {
  background: var(--page-background-color);
  border-radius: 8px;
}
.scrollbarP::-webkit-scrollbar-thumb, .dropdownP .p-dropdown-items-wrapper::-webkit-scrollbar-thumb {
  background-clip: padding-box;
  border-radius: 8px;
  background-color: var(--dropdown-icon-color);
}

/* css needed to insert html from value in faq with styles */
.faqHtml a, .faqHtml a:visited{
  text-decoration: none !important;
  color: var(--faq-unselected-title-color) !important;
  font-weight: bold !important;
}
.faqHtml a:hover{
  text-decoration: none !important;
  color: var(--faq-selected-title-color) !important;
  font-weight: bold !important;
}

</style>
