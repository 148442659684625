<template>
    <div class="footer page" style="background-color: var(--page-background-color)">
        <div class="p-d-flex p-jc-center p-flex-column action-link-portal" style="padding: 1em">
          <div> <p class="footer-text"> <a style="visited:" @click="goToPortal()"> {{footerText1}} </a></p></div>
          <div style="padding-left: 2em; padding-right: 2em;"><hr style="width: 100%"/></div>
          <div class="titlecss">
            <a @click="goToView()" class="action-link">
              <div class="p-d-flex p-jc-center">
                <div>
                  <img :alt="logo" :src="titleImgPath" class="imgstyle">
                </div>
                <div class="p-mr-2">
                  {{footerText2}}
                </div>
              </div>
            </a>
          </div>
        </div> 
    </div>
    <ExceptionModalClean
      ref="modalError"
      tipo="error"
      messageErr="Falha na comunicação."
      detailErr="Houve falha na comunicação. Tente novamente mais tarde."
    />
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  data() {
    return {
      titleImgPath:require('@/assets/icon-atendimento-black.svg'),
      logo:'alt'
    }
  },
  name: 'Footer',
  props: {
    footerText1: String,
    footerText2: String
  },
  methods: {
    goToPortal() {
      var url = "https://openbankingbrasil.org.br/";
      var requestOptions = {
        method: 'GET',
        redirect: 'follow',
        mode:'no-cors'
      };
        
      fetch(url, requestOptions)
        .then( (response) => {
          window.location.href = url;
        })
        .catch((err) =>{
          this.$refs.modalError.enable();
        });
    },
    goToView(){
      this.$router.push({name: 'contactUs' });
    }
  }
});
</script>

<style scoped>

.titlecss{
  color: var(--footer-attendance-text2-color);
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 16px;
  text-align: center;
}

.imgstyle{
  padding-right:0.35em;
  margin-top: auto;
}

.footer {
  left: 0;
  bottom: 0;
  width: 100%;
}

a {
  color: var(--footer-attendance-text1-color);
  text-decoration: none; 
}

.action-link {
  color: var(--footer-attendance-text2-color) !important; 
  text-decoration: none !important;
  cursor: pointer;
}
.action-link-portal{
  cursor: pointer;
}

.footer-text 
{
  color: var(--footer-attendance-text1-color);
  text-align: center;
  font-style: normal;
  font-weight: 550;
  font-size: 14px;
  line-height: 16px;
  text-decoration: none;
}

hr{
  background-color: var(--confcons-hr-color);
  border: 0;
  clear:both;
  display:block;
  width: 100%;
  height: 0.5px;
  margin-top:0;
  margin-bottom: 10px;
}

</style>
