<template>
    
  <div>

    <template v-if="ifChoose == 'amz'">
      <div class="loaderContainer">
        <img class="rotateClockwise fsticon" alt="icon-load1" src="@/assets/loader/icon-loader-basa1.svg">
        <img class="rotateCounterClockwise sndicon" alt="icon-load2" src="@/assets/loader/icon-loader-basa2.svg">
        <img class="rotateClockwise trdicon" alt="icon-load3" src="@/assets/loader/icon-loader-basa3.svg">
      </div>
    </template>

    <template v-if="ifChoose == 'bcd'">
      <div class="loaderContainer">
        <img class="rotateClockwise fsticon" alt="icon-load1" src="@/assets/loader/icon-loader-brasilcard1.svg">
        <img class="rotateCounterClockwise sndicon" alt="icon-load2" src="@/assets/loader/icon-loader-brasilcard2.svg">
        <img class="rotateClockwise trdicon" alt="icon-load3" src="@/assets/loader/icon-loader-brasilcard3.svg">
      </div>
    </template>

    <template v-if="ifChoose == 'bmx'">
      <div class="loaderContainer">
        <img class="rotateClockwise fsticon" alt="icon-load1" src="@/assets/loader/icon-loader-maxima1.svg">
        <img class="rotateCounterClockwise sndicon" alt="icon-load2" src="@/assets/loader/icon-loader-maxima2.svg">
        <img class="rotateClockwise trdicon" alt="icon-load3" src="@/assets/loader/icon-loader-maxima3.svg">
      </div>
    </template>

    <template v-if="ifChoose == 'bs2'">
      <div class="loaderContainer2">
        <img class="rotateClockwise fsticon" alt="icon-load1" height="98" src="@/assets/loader/bs2-loading.svg">
      </div>
    </template>

    <template v-if="ifChoose == 'ccb'">
      <div class="loaderContainer">
        <img class="rotateClockwise fsticon" alt="icon-load1" src="@/assets/loader/icon-loader-ccb1.svg">
        <img class="rotateCounterClockwise sndicon" alt="icon-load2" src="@/assets/loader/icon-loader-ccb2.svg">
        <img class="rotateClockwise trdicon" alt="icon-load3" src="@/assets/loader/icon-loader-ccb3.svg">
      </div>
    </template>

    <template v-if="ifChoose == 'cds'">
      <div class="loaderContainer">
        <img class="fsticon" alt="icon-load1" src="@/assets/loader/credsis-loading.svg">
      </div>
    </template>

    <template v-if="ifChoose == 'exb'">
      <div class="loaderContainer">
        <img class="rotateClockwise fsticon" alt="icon-load1" src="@/assets/loader/icon-loader1.svg">
        <img class="rotateCounterClockwise sndicon" alt="icon-load2" src="@/assets/loader/icon-loader2.svg">
        <img class="rotateClockwise trdicon" alt="icon-load3" src="@/assets/loader/icon-loader3.svg">
      </div>
    </template>

    <template v-if="ifChoose == 'let'">
      <div class="loaderContainer">
        <img class="rotateClockwise fsticon" alt="icon-load1" src="@/assets/loader/icon-loader-lets1.svg">
        <img class="rotateCounterClockwise sndicon" alt="icon-load2" src="@/assets/loader/icon-loader-lets2.svg">
        <img class="rotateClockwise trdicon" alt="icon-load3" src="@/assets/loader/icon-loader-lets3.svg">
      </div>
    </template>

    <template v-if="ifChoose == 'ori'">
      <div class="loaderContainer">
        <img class="rotateClockwise fsticon" alt="icon-load1" src="@/assets/loader/icon-loader-ouri1.svg">
        <img class="rotateCounterClockwise sndicon" alt="icon-load2" src="@/assets/loader/icon-loader-ouri2.svg">
        <img class="rotateClockwise trdicon" alt="icon-load3" src="@/assets/loader/icon-loader-ouri3.svg">
      </div>
    </template>

    <template v-if="ifChoose == 'voi'">
      <div class="loaderContainer">
        <img class="rotateClockwise fsticon" alt="icon-load1" src="@/assets/loader/icon-loader-voiter1.svg">
        <img class="rotateCounterClockwise sndicon" alt="icon-load2" src="@/assets/loader/icon-loader-voiter2.svg">
        <img class="rotateClockwise trdicon" alt="icon-load3" src="@/assets/loader/icon-loader-voiter3.svg">
      </div>
    </template>

  </div>

</template>

<script>
import { getTheme } from '@/util/util.js';

export default {
  props: {
    rotate: Boolean
  },
  data() {
    return {
      ifChoose: '',
    }
  },
  created(){
    this.ifChoose = getTheme();
  }
}
</script>

<style scoped>

.loaderContainer{
  display: inline-block;
  position: relative;
  top:0;
  left:0;
  width:171px;
  height:171px;
}
.loaderContainer2{
  display: inline-block;
  position: relative;
  top:0;
  left:0;
  width:98px;
  height:98px;
}
.fsticon{
  position: absolute;
  top:0px;
  left:0px;
}
.sndicon{
  position: absolute;
  top:20px;
  left:20px;
}
.trdicon{
  position: absolute;
  top:41.5px;
  left:41.5px;
}

.rotateClockwise {
  animation: rotationClockwise 2s infinite linear;
}
@keyframes rotationClockwise {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.rotateCounterClockwise {
    animation: rotationCounterClockwise 2s infinite linear;
}
@keyframes rotationCounterClockwise {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-359deg);
  }
}
</style>