<template>

  <BModal ref="bmodal">

    <div class="header">{{header}}</div>
    <div class="space"></div>
      
    <div v-if="isCancel">
      <span class="description">
        {{part1}}
      </span>

      <span class="description descriptionBold">
        {{part2}}
      </span>

      <span class="description">
        {{part3}}
      </span>
      <div class="space"></div> 
    </div>

    <div v-else>
      <div class="description">{{description}}</div>
      <div class="space"></div> 
    </div>
      
    <Mbutton
      :rotulo="fstButtonTxt"
      tipo="button"
      class="modalConfirm"
      :confirmacao="false"
      @botaoAtivado="makeAction()"
    />
    <div class="space"></div>

    <Bbutton
      :rotulo="sndButtonTxt"
      tipo="submit"
      class="modalCancel"
      :confirmacao="false"
      @botaoAtivado="disable()"
    />
      
  </BModal>

</template>

<script>

export default {
  props: {
    header: String,
    isCancel: Boolean,
    boldString: String,
    description: String,
    fstButtonTxt: String,
    sndButtonTxt: String,
    action: Function
  },
  data() {
    return {
      showModal: false,
      part1:'',
      part2:'',
      part3:''
    }
  },
  methods: {
    makeAction(){
      this.$emit('action');
    },
    enable(){
      this.$refs.bmodal.enable();
    },
    disable(){
      this.$refs.bmodal.disable();
    },
    isEmpty(text){
      if (text === 'undefined' || text == null) 
        return true;

      return text.replace(/\s/g, '').length < 1;
    }
  },
  created(){
    let holder = this.description;

    if (this.isCancel){
      if (holder.includes(this.boldString) && !this.isEmpty(this.boldString)){
        var parts = holder.split(this.boldString);
        this.part1=parts[0];
        this.part2=this.boldString;
        this.part3=parts[1];
      }
      else{
        this.part1=holder;
        this.part2='';
        this.part3='';
      }
    }
  }
};
</script>

<style scoped>

.header{
  margin-top: 8px;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  color: var(--confcons-modal-main-title-color);
}
.description{
  font-size: 16px;
  line-height: 24px;
  padding: none;
  margin-bottom: 4px;
  color: var(--confcons-modal-text-color);
}
.space{
  padding: none;
  margin: none;
  height: 14px;
}
.descriptionBold{
  font-weight: var(--confcons-modal-cancel-bold-not-weight) !important;
  color: var(--confcons-modal-cancel-bold-not-color);
}

</style>