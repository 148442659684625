<template>

  <div class="disableScreen" v-show="showModal || type=='iframe'" :class="customClassDisable"></div>

  <div class="boxCenteredIframe" v-if="type=='iframe'">
      
    <slot></slot>
      
  </div>

  <div class="boxCentered" v-if="type!='iframe'" :class="customClass" v-show="showModal">

    <slot></slot>
      
  </div>

</template>

<script>

export default {
  props: {
    customClass: String,
    customClassDisable: String,
    type: String
  },
  data() {
    return {
      showModal: false,
    }
  },
  methods: {
    enable(){
      this.showModal = true;
    },
    disable(){
      this.showModal = false;
    }
  }
};
</script>

<style scoped>

.disableScreen{
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
}
.boxCentered {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  text-align: center;
  padding: 1em;
  background: var(--page-background-color);
  box-sizing: border-box;
  box-shadow: 0px 4px 32px rgba(128, 128, 128, 0.3);
  border-radius: 15px;
  width: 90%;
}
.aboutBoxModal{
  padding: 0px 0px 0px 0px;
  margin: 0px;
  box-shadow: none;
}
.conditionBoxModal{
  padding: 0px 0px 1em 0px;
  margin: 0px;
  height: 50%;
  box-shadow: none;
}
.boxCenteredIframe{
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.filterModalBox{
  height: auto;
  width: auto;
}
.filterModalDisable{
  background-color: var(--exceptions-backgroundup-transparency-color);
}
</style>