
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'NavBar',
  props: {
    ifName: String,
    bntGoBackLabel: String
  },
  data() {
    return {
      managerNav:false,
      showGoBack: true,
    }
  },
  methods: {
    showClientNavbar(){
      this.managerNav = false;
    },
    showManagerNavbar(){
      this.managerNav = true;
    },
    showGoBackButton() {
      this.showGoBack = true;
    },
    hideGoBackButton() {
      this.showGoBack = false;
    },
  }
});
