
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ConsentStatusCardDetail',
  props: {
    id: Number,
    detailView: String,
    cardStatus: String,
    cardStatusTitle: String,
    cardStatusDescription: String,
    cardInfoTitle: String,
    cardInfoDescription: String,
    initiatorTitle: String,
    initiatorName: String,
    payerDetailTitle: String,
    payerCpf: String,
    payerName: String,
    payerBranch: String,
    payerCc: String,
    localInstrument: String,
    beneficiaryDetailTitle: String,
    beneficiaryCnpj: String,
    beneficiaryName: String,
    beneficiaryPixLabel: String,
    beneficiaryPixValue: String,
    beneficiaryBankLabel: String,
    beneficiaryBankCode: String,
    beneficiaryAggLabel: String,
    beneficiaryAgg: String,
    beneficiaryAccount: String,
    beneficiaryQRDinLabel: String,
    beneficiaryQREstLabel: String,
    consentCreatorLabel: String,
    consentCreatorName: String,
    initiationDateTitle: String,
    scheduledDateTitle: String,
    initiationDate: String,
    scheduledDate: String,
    valueTitle: String,
    value: String,
    paymentFormTitle: String,
    paymentForm: String,
    consentIDTitle: String,
    consentID: String,
    haveDebtorAccount: Boolean,
    payFeeTitle: String,
    payFee: String
  },
  methods: {
    test(message: string) : void {
      console.log(message);
    },
    getCssClassBox(status: string) : string {
      return 'p-grid box box-' + status;
    },
    getCssClass(status: string) : string {
      return 'p-col-12 card-header card-header-' + status;
    },
    getCssClassToggler(status: string) : string {
      return 'p-m-auto toggler toggler-' + status;
    },
    getCssClassDescriptionContainer(status: string) : string {
      return 'card-status-description-container card-status-description-container-' + status;
    },
    getCssDebtorData(haveDebtorData: boolean) : string {
      return haveDebtorData ? 'display: block' : 'display: none';
    },
    goToView(viewName: string, params:  any) : void {
      this.$router.push({ name: viewName, params: params});
    }
  }
});
