<template>

  <BModal id="conditions" ref="bmodalaconditions" customClass="conditionBoxModal">

    <div class="p-d-flex p-p-3 card colorback border15">

      <div class="p-d-flex p-ai-center p-mr-2 if-container-padding terms">
        Termos e Condições
      </div>
      
      <div class="p-ml-auto if-container-padding">
        <div class="p-mr-2 if-container-padding">
          <CloseButton @action="disable()"/>
        </div>
      </div>

    </div>

    <div class="space"></div>
  
    <div class="textConditions pscrollbar">
      Aqui cada instituição irá incluir seus próprios termos e condições lorem ipsum dolor 
      sit amet consectetur adipiscing elit. lorem ipsum dolor sit amet consectetur adipiscing elit. 
      lorem ipsum dolor sit amet consectetur adipiscing elit. lorem ipsum dolor sit amet consectetur 
      adipiscing elit. lorem ipsum dolor sit amet consectetur adipiscing elit. lorem ipsum dolor sit
      amet consectetur adipiscing elit. lorem ipsum dolor sit amet consectetur adipiscing elit. 
      lorem ipsum dolor sit amet consectetur adipiscing elit. lorem ipsum dolor sit amet consectetur 
      adipiscing elit. lorem ipsum dolor sit amet consectetur adipiscing elit. lorem ipsum dolor sit 
      amet consectetur adipiscing elit. lorem ipsum dolor sit amet consectetur adipiscing elit. lorem 
      ipsum dolor sit amet consectetur adipiscing elit. lorem ipsum dolor sit amet consectetur adipiscing 
      elit. lorem ipsum dolor sit amet consectetur adipiscing elit. lorem ipsum dolor sit amet consectetur 
      adipiscing elit. lorem ipsum dolor sit amet consectetur adipiscing elit. lorem ipsum dolor sit amet 
      consectetur adipiscing elit. lorem ipsum dolor sit amet consectetur adipiscing elit. lorem ipsum 
      dolor sit amet consectetur adipiscing elit. lorem ipsum dolor sit amet consectetur adipiscing elit. 
      lorem ipsum dolor sit amet consectetur adipiscing elit. lorem ipsum dolor sit amet consectetur 
      adipiscing elit. lorem ipsum dolor sit amet consectetur adipiscing elit. lorem ipsum dolor sit amet 
      consectetur adipiscing elit. lorem ipsum dolor sit amet consectetur adipiscing elit. lorem ipsum dolor 
      sit amet consectetur adipiscing elit. lorem ipsum dolor sit amet consectetur adipiscing elit. lorem 
      ipsum dolor sit amet consectetur adipiscing elit. lorem ipsum dolor sit amet consectetur adipiscing 
      elit. lorem ipsum dolor sit amet consectetur adipiscing elit. lorem ipsum dolor sit amet consectetur 
      adipiscing elit. lorem ipsum dolor sit amet consectetur adipiscing elit. lorem ipsum dolor sit amet 
      consectetur adipiscing elit.
      Aqui cada instituição irá incluir seus próprios termos e condições lorem ipsum dolor 
      sit amet consectetur adipiscing elit. lorem ipsum dolor sit amet consectetur adipiscing elit. 
      lorem ipsum dolor sit amet consectetur adipiscing elit. lorem ipsum dolor sit amet consectetur 
      adipiscing elit. lorem ipsum dolor sit amet consectetur adipiscing elit. lorem ipsum dolor sit
      amet consectetur adipiscing elit. lorem ipsum dolor sit amet consectetur adipiscing elit. 
      lorem ipsum dolor sit amet consectetur adipiscing elit. lorem ipsum dolor sit amet consectetur 
      adipiscing elit. lorem ipsum dolor sit amet consectetur adipiscing elit. lorem ipsum dolor sit 
      amet consectetur adipiscing elit. lorem ipsum dolor sit amet consectetur adipiscing elit. lorem 
      ipsum dolor sit amet consectetur adipiscing elit. lorem ipsum dolor sit amet consectetur adipiscing 
      elit. lorem ipsum dolor sit amet consectetur adipiscing elit. lorem ipsum dolor sit amet consectetur 
      adipiscing elit. lorem ipsum dolor sit amet consectetur adipiscing elit. lorem ipsum dolor sit amet 
      consectetur adipiscing elit. lorem ipsum dolor sit amet consectetur adipiscing elit. lorem ipsum 
      dolor sit amet consectetur adipiscing elit. lorem ipsum dolor sit amet consectetur adipiscing elit. 
      lorem ipsum dolor sit amet consectetur adipiscing elit. lorem ipsum dolor sit amet consectetur 
      adipiscing elit. lorem ipsum dolor sit amet consectetur adipiscing elit. lorem ipsum dolor sit amet 
      consectetur adipiscing elit. lorem ipsum dolor sit amet consectetur adipiscing elit. lorem ipsum dolor 
      sit amet consectetur adipiscing elit. lorem ipsum dolor sit amet consectetur adipiscing elit. lorem 
      ipsum dolor sit amet consectetur adipiscing elit. lorem ipsum dolor sit amet consectetur adipiscing 
      elit. lorem ipsum dolor sit amet consectetur adipiscing elit. lorem ipsum dolor sit amet consectetur 
      adipiscing elit. lorem ipsum dolor sit amet consectetur adipiscing elit. lorem ipsum dolor sit amet 
      consectetur adipiscing elit. 
    </div>

  </BModal>
  
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Conditions Box',
  props: {
    ifName: String,
    bntGoBackLabel: String
  },
  data(){
    return {
    }
  },
  methods: {
    
    enable(){
      this.$refs.bmodalaconditions.enable();
    },

    disable(){
      this.$refs.bmodalaconditions.disable();
    },

  }
});
</script>

<style scoped>

.colorback {
  background-color:var(--navbar-background-color);
}
.border15 {
  border-radius: 15px 15px 0px 0px;
}
.terms {
  color: var(--navbar-go-back-button-text-color);
  padding-left: 1rem;
  font-size: 24px;
}
.space{
  padding: none;
  margin: none;
  height: 1px;
}
.textConditions {
  position: absolute;
  padding: 2rem;
  height: calc(100% - 86px);
  width: 99.5%;
  overflow-y: auto;
}
.pscrollbar::-webkit-scrollbar {
  width: 8px;
}
.pscrollbar::-webkit-scrollbar-track {
  background: var(--navbar-go-back-button-background-hover-color);
  border-radius: 8px;
}
.pscrollbar::-webkit-scrollbar-thumb {
  background-clip: padding-box;
  border-radius: 8px;
  background-color: var(--navbar-go-back-button-icon-hover-color);
}
</style>