
<template>
  <div class="painel" :style="pstyle">
    
    <h2 class="painel-titulo" :style="ptstyle">
      <div :style="ftype">
        {{ titulo }}
      </div>
      <div :style="ftype2">
        {{valor}}
      </div> 
    </h2>

    <div class="painel-conteudo" :style="pcstyle">
      <slot></slot>
    </div>

  </div>
</template>

<script>
export default {

  props: ['titulo','valor','pstyle','ptstyle','pcstyle','ftype','ftype2'],

  data() {
    return {}
  }
}

</script>

<style scoped>

.painel {
  border-radius: 15px;
  box-shadow: var(--shadow-default);
}
.painel .painel-titulo {
  margin-left: 17px;
  margin-right: 15px;
  text-align: left;
  border-radius: 8px;
}
.painel-conteudo{
  box-sizing: border-box;
  border-radius: 15px;
}


</style>