const AppRoutes = [
    '/consultConsents',
    '/myConsents',
    '/selectAccount/',
    '/confirmConsent',
    '/faq',
    '/contactUs',
    '/redirect',
    '/home',
];

const FetchStates = {
    TecbanTimeout: 'TecbanTimeout',
    MaxTimeout: 'MaxTimeout',
    FetchError: 'FetchError'
}

const SessionObjects = {
    LAST_SELECTED_CONSENT : 'LAST_SELECTED_CONSENT',
    BACK_TO_CONSENT_LIST : 'BACK_TO_CONSENT_LIST'
}

const AccountTypes =  {
    CACC : "CACC",
    SLRY : "SLRY", 
    SVGS : "SVGS",
    TRAN : "TRAN"
}

const ConsentStatus = {
    WAITING : "AWAITING_AUTHORISATION",
    ACCEPTED : "AUTHORISED", 
    REJECTED : "REJECTED",
    CONSUMED : "CONSUMED",
}

function GetAccountType(accountType) {

    var ret = "Tipo de conta inválido";

    switch(accountType) {
        case AccountTypes.CACC: ret = "Conta corrente"; break;
        case AccountTypes.SLRY: ret = "Conta salário"; break;
        case AccountTypes.SVGS: ret = "Conta poupança"; break;
        case AccountTypes.TRAN: ret = "Conta de pagamento pré-paga"; break;
    }

    return ret;
}

function GetConsentStatus(status) {

    var ret = "Status Indefinido";

    var internalization = getInternalization();
    
    if(internalization == null){
        switch(status) {
            case ConsentStatus.WAITING: ret = "Aguardando aprovação"; break;
            case ConsentStatus.ACCEPTED: ret = "Autorizado"; break;
            case ConsentStatus.REJECTED: ret = "Rejeitado"; break;
            case ConsentStatus.CONSUMED: ret = "Consumido"; break;
        }
    }
    else{
        switch(status) {
            case ConsentStatus.WAITING: ret = internalization.myConsAndDetailConsUpBoxTitleWaiting; break;
            case ConsentStatus.ACCEPTED: ret = internalization.myConsAndDetailConsUpBoxTitleAccepted; break;
            case ConsentStatus.REJECTED: ret = internalization.myConsAndDetailConsUpBoxTitleRejected; break;
            case ConsentStatus.CONSUMED: ret = internalization.myConsAndDetailConsUpBoxTitleConsumed; break;
        }
    }

    return ret;
}

function GetConsentCssClass(status) {

    var ret = "expired";
    
    switch(status) {
        case ConsentStatus.WAITING: ret = "waiting"; break;
        case ConsentStatus.ACCEPTED: ret = "authorised"; break;
        case ConsentStatus.REJECTED: ret = "rejected"; break;
        case ConsentStatus.CONSUMED: ret = "consumed"; break;
    }

    return ret;
}

function MaskCpfCnpj(rawCpfCnpj, size) {
    return size == 11
        ? rawCpfCnpj.replace(/^(\d{3})(\d{3})(\d{3})(\d{2}).*/, '$1.$2.$3-$4')
        : rawCpfCnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2}).*/, '$1.$2.$3/$4-$5');
}

function MaskCpfCnpjBlur(rawCpfCnpj, size) {
    return size == 11
        ? rawCpfCnpj.replace(/^(\d{3})(\d{3})(\d{3})(\d{2}).*/, '$1$2$3$4')
        : rawCpfCnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2}).*/, '$1$2$3$4$5');
}
//function MaskCpfCnpj(rawCpfCnpj, size) {
//    return size == 11
//       ? rawCpfCnpj.replace(/^(\d{3})(\d{3})(\d{3})(\d{2}).*/, '***.$2.$3-**')
//        : rawCpfCnpj.replace(/^(\d{2})(\d{1})(\d{2})(\d{3})(\d{4})(\d{2}).*/, '**.*$3.$4/$5-**');
    
     
//}
//function MaskCpfCnpjBlur(rawCpfCnpj, size) {
//    return size == 11
//        ? rawCpfCnpj.replace(/^(\d{3})(\d{3})(\d{3})(\d{2}).*/, '***$2$3**')
//        : rawCpfCnpj.replace(/^(\d{2})(\d{1})(\d{2})(\d{3})(\d{4})(\d{2}).*/, '***$3$4$5**');
//}

function setWithoutExpiry(key, value) {
    localStorage.setItem(key, value);
}
function getWithoutExpiry(key) {
	const itemStr = localStorage.getItem(key);
    
	// if the item doesn't exist, return null
	if (!itemStr) {
		return null;
	}

	return itemStr;
}

function setWithExp(key, value) {
    sessionStorage.setItem(key, value)
}

function getWithExp(key) {
	const itemStr = sessionStorage.getItem(key);
    
	// if the item doesn't exist, return null
	if (!itemStr) {
		return null;
	}

	return itemStr;
}

function setWithExpiry(key, value, ttl) {
	const now = new Date();
    let parsedExpiry = (ttl != 0 ? now.getTime() + ttl : 0);

	// `item` is an object which contains the original value
	// as well as the time when it's supposed to expire
	const data = {
		value: value,
		expiry: parsedExpiry
	}
	localStorage.setItem(key, JSON.stringify(data));
}
function getWithExpiry(key) {
	const itemStr = localStorage.getItem(key);
	// if the item doesn't exist, return null
    //console.log();
	if (!itemStr) {
		return null;
	}
	const item = JSON.parse(itemStr);
	const now = new Date();
	// compare the expiry time of the item with the current time
	if (item.expiry != 0 && now.getTime() > item.expiry) {
		// If the item is expired, delete the item from storage
		// and return null
		localStorage.removeItem(key);
		return null;
	}
	return item.value;
}

function endSess(key){
    localStorage.removeItem(key)
}

function getTheme(){
    
    if (process.env.VUE_APP_IF_NAME.toLowerCase().trim() == 'qa' && getWithoutExpiry('theme') == null){
        setWithoutExpiry('theme', 'exb');
    }
    else if (
        process.env.VUE_APP_IF_NAME.toLowerCase().trim() != 'qa' && 
        getWithoutExpiry('theme') != process.env.VUE_APP_IF_NAME.toLowerCase().trim()){

        setWithoutExpiry('theme', process.env.VUE_APP_IF_NAME.toLowerCase().trim());
    }

    return getWithoutExpiry('theme');
}

function getInternalization(){
    return JSON.parse(getWithExp('internalization'));
}

function setLogoIniciadora(logoUrl){
    localStorage.setItem('logoIni', logoUrl);
}

function getLogoIniciadora(){
    return localStorage.getItem('logoIni');
}

function utf8_to_b64( str ) {
    return window.btoa(unescape(encodeURIComponent( str )));
  }
  
function getAuthorization(){
      return "Basic " + utf8_to_b64(process.env.VUE_APP_CLIENT_ID + ':' + process.env.VUE_APP_CLIENT_SECRET);
}

// Valida se a urn passada é valida ou não, baseado na regex abaixo
//^urn:[a-z0-9][a-z0-9-]{1,31}:([a-z0-9()+,.:=@;$_!*'-]|%[0-9a-f]{2})+$
//urn:TECBAN:pix-178b0c69-a1cc-477e-9a2d-a831dfcaeaf4
function isAValidUrn(urn) {

    var ret = false;

    if(urn != null && urn != undefined) {
        let paragraph = urn.trim();
        let regex = /urn:[a-z0-9][a-z0-9-]{1,31}:([a-z0-9()+,.:=@;$_!*'-]|%[0-9a-f]{2})+$/igm;
        let found = paragraph.match(regex);
        ret = (found != null && found != undefined);
    }
    
    return ret;
}

async function doSomeWork(time) {
    //console.log("working...");
    await new Promise(resolve => setTimeout(resolve, time));
    //console.log("work finished!");
}
  
export 
{ 
    SessionObjects,
    AccountTypes, 
    ConsentStatus,
    AppRoutes,
    FetchStates 
};

export 
{ 
    GetAccountType, 
    GetConsentStatus, 
    GetConsentCssClass,
    MaskCpfCnpj,
    getTheme,
    getInternalization,
    setWithoutExpiry,
    getWithoutExpiry,
    setWithExp,
    getWithExp,
    setWithExpiry,
    getWithExpiry,
    setLogoIniciadora,
    getLogoIniciadora,
    endSess,
    isAValidUrn,
    doSomeWork,
    MaskCpfCnpjBlur
};