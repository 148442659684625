
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ConsentStatusCard',
  props: {
    id: String,
    detailView: String,
    cardStatus: String,
    cardStatusTitle: String,
    cardStatusDescription: String,
    cardInfoTitle: String,
    cardInfoDescription: String
  },
  methods: {
    test(message: string) : void {
      console.log(message);
    },
    getCssClass(status: string) : string {
      return 'p-col-12 card-header card-header-' + status;
    },
    goToView(viewName: string, id: string) : void {
      sessionStorage.setItem('LAST_SELECTED_CONSENT', id);
      this.$router.push({ name: viewName });
    }
  }
});
