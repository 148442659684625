<template>

    <Button class="okbutton"
        :type="tipo" @click="disparaAcao()"
        :style="customButtons">
          {{rotulo}}
    </Button>

</template>

<script>
  export default {
    data(){
      return {
        customButtons:{
        }
      }
    },
    created() {
      this.customButtons={
        backgroundColor:'var(--info-modal-myconsents-okbutton-background-color)',
        color:'var(--info-modal-myconsents-okbutton-text-color)',
        border: '1px solid var(--info-modal-myconsents-okbutton-border-color)',
        'border-radius': '20px',
        padding: '0px 20px 0px 20px',
        margin: 'none',
      }
    },
    name: "Okbutton",
    props: {
      tipo: String,
      rotulo: String,
      width: String,
      height: String
    },
    methods: {
      disparaAcao() {
        this.$emit('botaoAtivado');
      }
    },
  }
</script>

<style scoped>

.okbutton:active{
  border: none;
  outline: none;
}

</style>