<template>

  <BManagerModal ref="bmodalerr" customClass="filterErrManagerModal" customClassDisable="filterModalDisable">
    
    <div class="redWrapper">
      <div>
        <div class="inlineVerticalBlock icon">
          <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M21.4415 13.9532L14.4362 1.97762C13.7329 0.758398 12.4221 0.00166216 11.0045 2.73545e-06C9.58669 -0.00165687 8.2754 0.751956 7.56336 1.97767L0.555425 13.9576C-0.171249 15.1795 -0.187688 16.7105 0.516176 17.9592C1.22063 19.2089 2.53966 19.9866 3.9744 19.9983L18.0123 19.9983C19.4619 19.9842 20.7784 19.2089 21.4828 17.9603C22.1863 16.7132 22.1706 15.1856 21.4415 13.9532ZM2.27808 14.9737L9.29121 2.98491C9.64637 2.37354 10.2978 1.99918 11.0021 2C11.7064 2.00083 12.3576 2.37673 12.7068 2.98222L19.7177 14.9672C20.0852 15.5885 20.0931 16.3533 19.7408 16.9777C19.3881 17.603 18.7287 17.9913 18.0025 17.9984L3.98253 17.9983C3.27193 17.9925 2.61123 17.6029 2.25844 16.9771C1.90604 16.3519 1.91427 15.5855 2.27808 14.9737ZM11.0003 15.9983C11.5528 15.9983 12.0007 15.5506 12.0007 14.9983C12.0007 14.4461 11.5528 13.9983 11.0003 13.9983C10.4479 13.9983 10 14.4461 10 14.9983C10 15.5506 10.4479 15.9983 11.0003 15.9983ZM12.0036 5.99835H10.003V12.9983H12.0036V5.99835Z" fill="#ED1846"/>
          </svg>
        </div>
        <div class="inlineVerticalBlock msg">
          <span class="errorText">{{modalMessage1}}<br>{{modalMessage2}}</span>
        </div>
      </div>
      <div v-if="showButtonOk" class="buttonWrapper">
        <Button type="button" class="errOkButton" @click="disable()"><span>OK</span></Button>
      </div>
    </div>

  </BManagerModal>

</template>

<script>

export default {
  data() {
    return {
      
    }
  },
  props: {
    modalMessage1: String,
    modalMessage2: String,
    showButtonOk: Boolean
  },
  methods: {
    enable(){
      this.$refs.bmodalerr.enable();
    },
    disable(){
      this.$refs.bmodalerr.disable();
    }
  }
};
</script>

<style scoped>

.redWrapper{
  background-color: #FFDEDE;
  padding: 19px;
}
.inlineVerticalBlock{
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  margin-bottom: 10px;
}
.icon{
  margin-left: 15px;
  margin-right: 10px;
}
.iconWarning{
  width: 32px;
}
.msg{
  margin-left: 10px;
  margin-right: 20px;
}
.errorText{
  color: #ED1846;
}
.buttonWrapper{
  margin-top: 10px;
  text-align: center;
}
.errOkButton{
  display: inline-block;
  text-align: center;
  font-weight: bold;
  color: #FFFFFF;
  background-color: #ED1846;
  border-radius: 8px;
  padding: 7px;
  width: 50%;
  box-shadow: 0px 2px 12px #C4C4C4;
  border: none;
}
.errOkButton:hover, .errOkButton:focus{
  background-color: rgb(245, 60, 100) !important;
}

</style>