import { createRouter, createWebHashHistory, createWebHistory ,RouteRecordRaw } from 'vue-router'

// Rota buscada em tempo de código
// Isso gera um pedaço de código separado (about.[hash].js) para esta rota
// Que usa lazy-load quando a rota é visitada.
const routes: Array<RouteRecordRaw> = [];

if(process.env.VUE_APP_ENABLE_DEV_VIEWS != '0'){
  routes.push({
    path: '/home',
    name: 'Home',
    component: () => import('../components/Home/Home.vue')
  });
  routes.push({
    path: '/map',
    name: 'Map',
    component: () => import('../views/Map.vue')
  });
  routes.push({
    path: '/',
    name: 'TppSimulator',
    component: () => import('../views/TppSimulator.vue')
  });
}

if(process.env.VUE_APP_ENABLE_CLIENT_CONFIRM_VIEWS != '0' || process.env.VUE_APP_ENABLE_CLIENT_CONSULT_VIEWS != '0'){
  routes.push({
    path: '/auth',
    name: 'auth',
    component: () => import('../views/BlankPage.vue')
  });
  routes.push({
    path: '/contactUs',
    name: 'contactUs',
    component: () => import('../views/ContactUs.vue')
  });
}

if(process.env.VUE_APP_ENABLE_CLIENT_CONFIRM_VIEWS != '0'){
  routes.push({
    path: '/confirmConsent',
    name: 'ConfirmConsent',
    component: () => import('../views/ConfirmConsent.vue')
  });
  routes.push({
    path: '/selectAccount/',
    name: 'SelectAccount',
    component: () => import('../views/SelectAccount.vue')
  });
  routes.push({
    path: '/redirect',
    name: 'Redirect',
    component: () => import('../views/Redirect.vue')
  });
  
}

if(process.env.VUE_APP_ENABLE_CLIENT_CONSULT_VIEWS != '0'){
  routes.push({
    path: '/consultConsents',
    name: 'ConsultConsents',
    component: () => import('../views/ConsultConsent.vue')
  });
  routes.push({
    path: '/myConsents',
    name: 'MyConsents',
    component: () => import('../views/MyConsents.vue')
  });
  routes.push({
    path: '/detailConsent',
    name: 'DetailConsent',
    component: () => import('../views/DetailConsent.vue')
  });
  routes.push({
    path: '/faq',
    name: 'faq',
    component: () => import('../views/FAQ.vue')
  });
  routes.push({
    path: '/consultConsent',
    name: 'consultConsent',
    component: () => import('../views/ConsultConsent.vue')
  });
}

if(process.env.VUE_APP_ENABLE_MANAGER_VIEWS != '0'){
  routes.push({
    path: '/manager/login',
    name: 'ManagerLogin',
    component: () => import('../views/Manager/ManagerLogin.vue'),
    alias: '/manager'
  });
  routes.push({
    path: '/login',
    name: 'LoginToken',
    component: () => import('../views/Manager/LoginToken.vue')
  });
  routes.push({
    path: '/manager/consultConsent',
    name: 'ManagerConsultConsent',
    component: () => import('../views/Manager/ManagerConsultConsent.vue')
  });
  routes.push({
    path: '/manager/detailConsent',
    name: 'ManagerDetailConsent',
    component: () => import('../views/Manager/ManagerDetailConsent.vue')
  });
}

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
