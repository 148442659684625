//módulos requeridos
import { createApp } from 'vue'
import App from './App.vue'
import PrimeVue from 'primevue/config'

//componentes
import InputText from 'primevue/inputtext';
import InputNumber from 'primevue/inputnumber';
import Button from 'primevue/button';
import ToolBar from 'primevue/toolbar';
import Divider from 'primevue/divider';
import Dropdown from 'primevue/dropdown';
import Card from 'primevue/card';
import Panel from 'primevue/panel';
import Message from 'primevue/message';
import RadioButton from 'primevue/radiobutton';
import Textarea from 'primevue/textarea';
import Navbar from '@/components/shared/NavBar.vue';
import IfInfo from '@/components/shared/IfInfo.vue';
import GoBackButton from '@/components/shared/GoBackButton.vue';
import Footer from '@/components/shared/Footer.vue';
import ImageFlexContainer from '@/components/shared/ImageFlexContainer.vue';
import TecbanCardShadow from '@/components/shared/TecbanCardShadow.vue';
import TecbanSimpleCardShadow from '@/components/shared/TecbanSimpleCardShadow.vue';
import ConsentStatusCard from '@/components/shared/ConsentStatusCard.vue';
import TextBox from '@/components/shared/TextBox.vue';
import MessagesManager from '@/components/shared/MessagesManager.vue';
import TitleP from '@/components/shared/TitleP.vue';
import ConsentStatusCardDetail from '@/components/shared/ConsentStatusCardDetail.vue';
import GreyContainer from '@/components/shared/GreyContainer.vue';
import PanelWBorder from '@/components/shared/PanelWBorder.vue';
import PanelRectangle from '@/components/shared/PanelRectangle.vue';
import Bbutton from '@/components/shared/Bbutton.vue';
import Cbutton from '@/components/shared/Cbutton.vue';
import Mbutton from '@/components/shared/Mbutton.vue';
import ConfirmCancelModal from '@/components/shared/ConfirmCancelModal.vue';
import Editbutton from '@/components/shared/Editbutton.vue';
import DialogContainer from '@/components/shared/DialogContainer.vue';
import Dialog from 'primevue/dialog';
import ExceptionModal from '@/components/shared/ExceptionModal.vue';
import ExceptionModalClean from '@/components/shared/ExceptionModalClean.vue';
import ExceptionModalOK from '@/components/shared/ExceptionModalOK.vue';
import ExceptionModalOKNoRedirect from '@/components/shared/ExceptionModalOKNoRedirect.vue';
import LoadTecbanIcon from '@/components/shared/LoadTecbanIcon.vue';
import LoadingModal from '@/components/shared/LoadingModal.vue';
import Okbutton from '@/components/shared/Okbutton.vue';
import BModal from '@/components/shared/BModal.vue';
import BManagerModal from '@/components/shared/BManagerModal.vue';
import AboutBox from '@/components/shared/AboutBox.vue';
import AboutPage from '@/components/shared/AboutPage.vue';
import ConditionsBox from '@/components/shared/ConditionsBox.vue';
import CloseButton from '@/components/shared/CloseButton.vue';
import LineSeparator from '@/components/shared/LineSeparator.vue';
import ModalAlwaysShow from '@/components/shared/ModalAlwaysShow.vue';
import LoadFillTecbanIcon from '@/components/shared/LoadFillTecbanIcon.vue';
import ConfirmModal from '@/components/shared/ConfirmModal.vue'
import TecbanRedirectCardShadow from '@/components/shared/TecbanRedirectCardShadow.vue';
import OnlyConfirmModal from '@/components/shared/OnlyConfirmModal.vue';
import AboutBoxWrapper from '@/components/shared/AboutBoxWrapper.vue';
import FilterCalendarModal from '@/components/shared/FilterCalendarModal.vue';
import FilterManagerCalendarModal from '@/components/shared/FilterManagerCalendarModal.vue';
import FilterManagerErrorModal from '@/components/shared/FilterManagerErrorModal.vue'
import Checkbox from 'primevue/checkbox';
import Calendar from 'primevue/calendar';
import InputMask from 'primevue/inputmask';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import ColumnGroup from 'primevue/columngroup';

//módulos configuráveis
import router from './router'
import i18n from '@/i18n'

//temas e utilidades css
import 'primeflex/primeflex.css';
import 'primevue/resources/themes/saga-blue/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
import '@/assets/themes/fonts/fonts.css';
import '@/assets/themes/stylesheet.css';

createApp(App)
    .use(i18n)
    .use(PrimeVue)
    .use(router)
    .component('InputText', InputText)
    .component('InputNumber', InputNumber)
    .component('MessagesManager',MessagesManager)
    .component('Button', Button)
    .component('ToolBar', ToolBar)
    .component('Divider', Divider)
    .component('Dropdown',Dropdown)
    .component('Calendar',Calendar)
    .component('Card', Card)
    .component('Dialog', Dialog)
    .component('InputMask',InputMask)
    .component('PanelWBorder',PanelWBorder)
    .component('Bbutton',Bbutton)
    .component('Mbutton',Mbutton)
    .component('Cbutton',Cbutton)
    .component('ExceptionModal',ExceptionModal)
    .component('DialogContainer',DialogContainer)
    .component('Panel', Panel)
    .component('Message', Message)
    .component('TitleP', TitleP)
    .component('IfInfo', IfInfo)
    .component('ExceptionModalOK',ExceptionModalOK)
    .component('ExceptionModalOKNoRedirect',ExceptionModalOKNoRedirect)
    .component('GoBackButton', GoBackButton)
    .component('NavBar', Navbar)
    .component('Footer', Footer)
    .component('ImageFlexContainer', ImageFlexContainer)
    .component('TecbanCardShadow', TecbanCardShadow)
    .component('TecbanSimpleCardShadow', TecbanSimpleCardShadow)
    .component('ConsentStatusCard', ConsentStatusCard)
    .component('TextBox', TextBox)
    .component('ConsentStatusCardDetail', ConsentStatusCardDetail)
    .component('GreyContainer', GreyContainer)
    .component('PanelWBorder', PanelWBorder)
    .component('PanelRectangle', PanelRectangle)
    .component('Bbutton', Bbutton)
    .component('Cbutton', Cbutton)
    .component('Mbutton', Mbutton)
    .component('ConfirmCancelModal', ConfirmCancelModal)
    .component('Editbutton', Editbutton)
    .component('RadioButton', RadioButton)
    .component('LoadTecbanIcon', LoadTecbanIcon)
    .component('LoadingModal', LoadingModal)
    .component('Okbutton',Okbutton)
    .component('BModal',BModal)
    .component('BManagerModal',BManagerModal)
    .component('AboutBox',AboutBox)
    .component('AboutPage',AboutPage)
    .component('ConditionsBox',ConditionsBox)
    .component('CloseButton',CloseButton)
    .component('LineSeparator',LineSeparator)
    .component('ModalAlwaysShow',ModalAlwaysShow)
    .component('LoadFillTecbanIcon',LoadFillTecbanIcon)
    .component('ConfirmModal',ConfirmModal)
    .component('Textarea', Textarea)
    .component('OnlyConfirmModal',OnlyConfirmModal)
    .component('TecbanRedirectCardShadow', TecbanRedirectCardShadow)
    .component('ExceptionModalClean',ExceptionModalClean)
    .component('AboutBoxWrapper',AboutBoxWrapper)
    .component('FilterCalendarModal',FilterCalendarModal)
    .component('FilterManagerCalendarModal',FilterManagerCalendarModal)
    .component('FilterManagerErrorModal',FilterManagerErrorModal)
    .component('Checkbox', Checkbox)
    .component('DataTable', DataTable)
    .component('Column', Column)
    .component('ColumnGroup', ColumnGroup)
    .mount('#app');
    